export const SelectInputField = ({
  field,
  register,
}: {
  field: any;
  register: any;
}) => {
  return (
    <div className="w-full">
      <label
        htmlFor={field?.name}
        className="block text-sm font-medium text-gray-700"
      >
        {field?.label} {field?.required && <span> *</span>}
      </label>
      <div className="mt-1">
        <select
          id={field?.name}
          name={field?.name}
          {...register(field?.name, { required: field?.required })}
          defaultValue={field?.defaultValue}
          className="py-3 px-4 block w-full focus:ring-2 focus:ring-yellow-300 focus:border-brand shadow-yellow-sm border-brand rounded-md"
        >
          {field?.unselectedLabel && (
            <option value="">{field.unselectedLabel}</option>
          )}
          {field?.options?.map((option, i) => (
            <option key={i} value={option?.value}>
              {option?.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
