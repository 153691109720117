export type TextField = {
  name?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: any;
};

export const TextInputField = ({
  field,
  register,
}: {
  field: TextField;
  register: any;
}) => {
  return (
    <div className="w-full">
      <label htmlFor={field?.name} className="block text-sm text-gray-900">
        {field?.label} {field?.required && <span> *</span>}
      </label>
      <div className="mt-1">
        <input
          type="text"
          name={field?.name}
          id={field?.name}
          {...register(field?.name, { required: field?.required })}
          autoComplete="name"
          placeholder={field?.placeholder}
          defaultValue={field?.defaultValue}
          className="py-3 px-4 block w-full focus:ring-2 focus:ring-yellow-300 focus:border-brand shadow-yellow-sm border-brand rounded-md"
        />
      </div>
    </div>
  );
};
