import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Richtext } from '@storyofams/storyblok-toolkit';
import { getCookies } from 'cookies-next';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { classNames, track } from '~/lib';

import { Button } from '../Button';
import { RichText, SuccessScreen } from '../Molecules';
import { CheckboxInputField } from './CheckboxInputField';
import { PhoneNumberInputField } from './PhoneNumberInputField';
import { SelectInputField } from './SelectInputField';
import { TextInputField } from './TextInputField';

export type Field = {
  name: string;
  fieldType: string;
  label: string;
  objectTypeId: string;
  hidden: boolean;
  required: boolean;
  placeholder?: string;
};

export type FormProps = {
  className?: string;
  content: {
    form_id: string;
    formFieldGroups: { fields: Field[] }[];
    button_text?: string;
    privacy_policy?: Richtext;
    privacy_policy_text?: Richtext;
    /**
     * @todo figure out which naming is the right one, seems like
     * camel case (`formType`) is actually used in storyblok for whitepapers?
     **/
    form_type?: string;
    formType?: string;
    event?: string;
    form_success_title?: string;
    form_success_subtext?: string;
  };
  extraFields?: any;
  setFormSuccess?: Dispatch<SetStateAction<boolean>>;
  contained?: boolean;
};

export const Form = ({
  content,
  extraFields,
  className,
  setFormSuccess,
  contained,
}: FormProps) => {
  const { query, asPath } = useRouter();
  const pageName = asPath?.split('?')[0]?.split('/')[1];

  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (values) => {
    setSuccess(false);
    setError('');

    const cookies = getCookies();
    const hubspotutk = cookies?.hubspotutk || null;

    try {
      const utmQueries = {
        utm_source: query?.utm_source || '',
        utm_medium: query?.utm_medium || '',
        utm_campaign: query?.utm_campaign || '',
        utm_content: query?.utm_content || '',
        utm_term: query?.utm_term || '',
      };

      const res = await fetch('/api/form', {
        method: 'post',
        body: JSON.stringify({
          fields,
          formId: content.form_id,
          ...values,
          ...utmQueries,
          ...extraFields,
          pageName,
          hutk: hubspotutk,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (res.status === 200) {
        setSuccess(true);

        if (!!setFormSuccess) {
          setFormSuccess(true);
        }

        reset();

        if ((!content?.form_type && !content?.formType) || !content?.event) {
          return;
        }

        track({
          formType: content.form_type || content.formType,
          event: content.event,
        });
      } else {
        const body = await res.json();
        const error =
          body?.errors?.[0]?.errorType === 'BLOCKED_EMAIL'
            ? 'Submission from this email address is not allowed. Please use your company email address.'
            : 'An unknown error has occurred';
        setError(error);
      }
    } catch (e) {
      setError('An unknown error has occurred');
    }

    return false;
  };

  const fields = content?.formFieldGroups
    ?.map((group) => group?.fields)
    ?.flat();

  return (
    <div className={contained ? '' : 'relative'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames('w-full space-y-8', className)}
      >
        {fields?.map((field, i) => {
          if (field?.hidden) {
            return null;
          }

          switch (field?.fieldType) {
            case 'text':
              return (
                <TextInputField key={i} field={field} register={register} />
              );
            case 'select':
              return (
                <SelectInputField key={i} field={field} register={register} />
              );
            case 'booleancheckbox':
              return (
                <CheckboxInputField key={i} field={field} register={register} />
              );
            case 'phonenumber':
              return (
                <PhoneNumberInputField
                  key={i}
                  field={field}
                  control={control}
                />
              );
          }

          return null;
        })}

        <Button
          variant="primary"
          aria-label="Submit"
          size="large"
          type="submit"
          isLoading={isSubmitting}
          className="w-full"
        >
          {content?.button_text || 'Submit'}
        </Button>

        {!!content?.privacy_policy && (
          <RichText
            text={content?.privacy_policy}
            textClassName="text-sm text-center"
          />
        )}
        {!!content?.privacy_policy_text && (
          <RichText
            text={content?.privacy_policy_text}
            textClassName="text-sm text-center"
          />
        )}
      </form>
      {error && (
        <div
          className={classNames('mt-4 text-lg sm:col-span-2', 'text-red-500')}
        >
          {error}
        </div>
      )}

      {success && (
        <SuccessScreen
          setSuccess={setSuccess}
          title={content?.form_success_title}
          subtext={content?.form_success_subtext}
        />
      )}
    </div>
  );
};
