import { Dispatch, SetStateAction } from 'react';
import { Heading, Button } from '..';
import { CheckCircle } from '../Icons';

export const SuccessScreen = ({
  setSuccess,
  title,
  subtext,
}: {
  setSuccess: Dispatch<SetStateAction<boolean>>;
  title?: string;
  subtext?: string;
}) => {
  return (
    <div className="absolute -inset-2 bg-white flex flex-col items-center justify-center z-10 rounded-2xl px-4">
      <div className="max-w-md flex flex-col items-center justify-center">
        <div className="bg-success-100 w-20 h-20 md:w-24 md:h-24 rounded-full flex items-center justify-center">
          <CheckCircle className="w-8 md:w-10 text-success-800 checkmark-circle" />
        </div>
        <Heading as="h2" variant="h2" className="mt-8 text-center">
          {title || "We'll be in touch!"}
        </Heading>
        <p className="mt-4 md:mt-5 text-center leading-6 text-grey-700">
          {subtext ||
            'Thank you for submitting your details. One of our eCommerce experts will contact you soon through email.'}
        </p>
        <Button
          variant="primary"
          size="large"
          className="max-w-xs w-full mt-8 md:mt-10"
          onClick={() => setSuccess(false)}
        >
          Submit another form
        </Button>
      </div>
    </div>
  );
};
