import { classNames } from '~/lib';

export const CheckboxInputField = ({
  field,
  register,
}: {
  field?: any;
  register?: any;
}) => {
  return (
    <div className="w-full">
      <div className="flex items-start">
        <label htmlFor="marketing_comms_accepted" className="flex items-start">
          <div className="h-4">
            <input
              type="checkbox"
              {...register(field?.name, {
                required: field?.required,
              })}
              className={classNames(
                'checked:text-gray-900 bg-white rounded border-transparent focus:ring-2 focus:ring-yellow-300 focus:ring-offset-0 focus:border-brand shadow-yellow-sm border border-brand mt-1 md:mt-0',
              )}
            />
          </div>
          <p className="text-base text-gray-900 ml-3">{field?.label}</p>
        </label>
      </div>
    </div>
  );
};
