import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export type PhoneNumberField = {
  name?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: any;
};

export const PhoneNumberInputField = ({
  field,
  control,
}: {
  field: PhoneNumberField;
  control: any;
}) => {
  return (
    <div className="w-full">
      <label htmlFor={field?.name} className="block text-sm text-gray-900">
        {field?.label} {field?.required && <span> *</span>}
      </label>
      <div className="mt-1">
        <Controller
          control={control}
          name={field?.name}
          rules={{ required: field?.required }}
          render={({ field: { ref, ...f } }) => (
            <PhoneInput
              {...f}
              placeholder={field?.placeholder}
              buttonClass="border !border-brand !bg-yellow-50"
              inputClass="!h-auto py-3 px-4 block w-full focus:ring-2 focus:ring-yellow-300 focus:border-brand shadow-yellow-sm !border-brand rounded-md !w-full !text-base"
              dropdownClass="!rounded-md overflow-hidden"
            />
          )}
        />
      </div>
    </div>
  );
};
